import React, {FC} from "react";
import cls from "./specialists.module.scss";
import {domainUrl} from "../../../helper";
import Specialist1 from "./SpecialistsImages/Specialist1.webp";
import Specialist2 from "./SpecialistsImages/Specialist2.webp";
import Specialist3 from "./SpecialistsImages/Specialist3.webp";
import Specialist4 from "./SpecialistsImages/Specialist4.webp";
import Link from "next/link";
import {LongArrowSvg} from "../../../svg";

interface IProps {
    dbName: string;
    translationsData: { [key: string]: string }
}

const Specialists: FC<IProps> = ({ dbName, translationsData }) => {

    const specialists = [
        { name: translationsData.Specialist1Name, role: translationsData.Specialist1Role, img: Specialist1 },
        { name: translationsData.Specialist2Name, role: translationsData.Specialist2Role, img: Specialist2 },
        { name: translationsData.Specialist3Name, role: translationsData.Specialist3Role, img: Specialist3 },
        { name: translationsData.Specialist4Name, role: translationsData.Specialist4Role, img: Specialist4 }
    ]

    return (
        <div className={`${cls.specialists_container} container`}>
            <h2>{translationsData.OurExperts}</h2>
            <div className={cls.specialists_row}>
                {specialists.map(({name, role, img }) => {
                    return (
                        <div className={cls.specialist_card}>
                            <div className={cls.specialist_content}>
                                <h3 className={cls.name}>{name}</h3>
                                <span className={cls.role}>{role}</span>
                            </div>
                            <div className={cls.arrow_bttn}>
                                <Link href="/cart">
                                    <LongArrowSvg/>
                                </Link>
                            </div>
                            <img
                                alt={name}
                                src={domainUrl(`${dbName}${img.src}`)}
                                className={cls.specialst_image}
                                loading="lazy"
                                width={325}
                                height={440}
                            />
                        </div>
                    )
                })}
            </div>
        </div>
    );
};

export default Specialists;